.report_container {
    margin-left: 5vh;
    margin-right: 5vh;
}
.content {
    padding-left: 1vh;
    padding-right: 1vh;
    z-index:1;
}
@media print {
    .content {
        height: 80vh!important;
    }
}
.footer {
    color:red;
    margin:0;
    z-index: 100;
}
body {
    text-align: justify!important;
    /* color:red!important */
    /* font-weight: 900!IMPORTANT; */
    font-family: 'Arial', sans-serif!important;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
      
  }

.tableContainer {
    width: 100%;
    border-collapse: collapse;
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    background-image: url('resources/image/opacityLow.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
.report_container {
    break-inside: avoid;
  }
.content {
    position: relative;
    top: 10vh;  
    z-index: 1;
  }
  .footer {
    position: fixed;
    height: 5vh;
    z-index: 100;
    bottom: 0;  
  }
.content{
  page-break-before: avoid;
  page-break-after: avoid;
  page-break-inside: avoid;
}