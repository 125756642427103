.header_container {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    margin: 0rem;
    border-bottom: 5px solid blue;
    /* z-index: 5; */
}

.header_navbar {
    width: 100%;
    margin: 0rem;
    padding: 0rem;
    /* background-color: rgb(0, 255 ,255); */
}

.navbar_brand {
    margin-left: 2rem;
}

.header_container.container-fluid{
    background-color: #f6f6f6;
}

.btn.btn-primary ,.btn.btn-success , .btn.btn-danger{
    background-color: #61b043!important;
    border-color: #61b043!important;
    border-radius: 5rem;
}
h2{
    color: #61b043;
}
  .custom-control-input:checked~.custom-control-label::before{
    background-color: #61b043!important;
    border-color: #61b043!important;
}